<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card
        class="md-theme-default md-card-wizard active"
        style="min-height: unset;"
      >
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <md-card-header class="md-card-header text-center">
              <h3 class="title">
                {{ title }}
              </h3>
              <h5 class="category">
                {{ subtitle }}
              </h5>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item">
                  <form-text
                    v-model="form.title"
                    name="title"
                    rules="required"
                    icon="code"
                    label="Title"
                    :disabled="!editable"
                  />
                </div>
                <div class="md-layout-item">
                  <form-text
                    v-model="form.description"
                    name="description"
                    rules="required"
                    icon="note"
                    label="Description"
                    :disabled="!editable"
                  />
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item">
                  <form-date
                    v-model="form.date"
                    name="date"
                    rules="required"
                    label="Date"
                    :disabled="!editable"
                  />
                </div>
                <div class="md-layout-item">
                  <LevelsSelect
                    v-model="form.course_level_id"
                    :disabled="!editable || $route.name === 'AssessmentDetails'"
                    rules="required"
                  />
                </div>
                <div
                  v-if="selClassroom"
                  class="md-layout-item"
                >
                  <FormText
                    v-model="selClassroom.classroom_name.name"
                    disabled
                    label="Classroom"
                  />
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 d-flex justify-content-center">
                  <md-button
                    v-if="!editable"
                    class="md-success"
                    @click="editable = true"
                  >
                    Edit Assessment Header
                  </md-button>
                  <md-button
                    v-if="editable && $route.name === 'AssessmentDetails'"
                    class="md-danger"
                    @click="editable = false"
                  >
                    Cancel Edit
                  </md-button>
                  <md-button
                    v-if="editable"
                    class="md-success ml-2"
                    @click="saveAssessment"
                  >
                    Save
                  </md-button>
                </div>
              </div>

              <div
                v-if="$route.name === 'AssessmentDetails'"
                class="md-layout"
              >
                <div class="md-layout-item">
                  <h4 class="text-center">
                    <b>Students</b>
                  </h4>
                  <vue-table
                    v-if="vTable.headers"
                    ref="vtable"
                    :values="vTable.values"
                    :headers="vTable.headers"
                    :actions="vTable.actions"
                    :options="{
                      tableClass:'table'
                    }"
                    :search="false"
                    @changed="getAssessmentStudents"
                    @onEdit="onEdit"
                    @onSaveItem="onSaveItem"
                    @onCancel="onCancel"
                  />
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <md-button
                class="btn-previous"
                @click.native="$router.push({name:'Assessments'})"
              >
                Return
              </md-button>
            </md-card-actions>
          </form>
        </ValidationObserver>
      </md-card>
    </div>
    <AssessmentSelector
      v-if="selectorOpen"
      :course-level-id="form.course_level_id"
      @close="selectorOpen=false"
      @onSelect="classroomSelected"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import {
  FormText,
  FormDate,
} from '@/components/Inputs';
import { LevelsSelect } from '@/components/Inputs/selects';
import moment from 'moment';
import AssessmentSelector from './AssessmentSelector.vue';

extend('required', required);
extend('email', email);

export default {
  components: {
    FormText,
    FormDate,
    VueTable,
    AssessmentSelector,
    LevelsSelect,
  },
  data() {
    return {
      selectorOpen: false,
      selClassroom: null,
      form: {
        title: null,
        description: null,
        date: null,
        course_level_id: 1,
      },
      title: 'Assessment Form',
      subtitle: 'Complete the form.',
      editable: true,
      vTable: {
        headers: [
          {
            title: 'programme.student.student_number',
            mask: 'S. Number',
            sortable: true,
            width: 10,
          },
          {
            title: 'programme.student.full_name',
            mask: 'Name',
          },
          {
            title: 'grade',
            mask: 'test g.',
            sortable: true,
            editable: true,
            editableType: 'number',
            width: 10,
          },
          {
            title: 'participative_grade',
            mask: 'participative g.',
            sortable: true,
            editable: true,
            editableType: 'number',
            width: 10,
          },
          {
            title: 'receptive_grade',
            mask: 'receptive g.',
            sortable: true,
            editable: true,
            editableType: 'number',
            width: 10,
          },
          {
            title: 'productive_grade',
            mask: 'productive g.',
            sortable: true,
            editable: true,
            editableType: 'number',
            width: 10,
          },
          {
            title: 'final_grade',
            mask: 'final g.',
            sortable: true,
            after: '%',
            width: 10,
          },
          {
            title: 'remark',
            sortable: true,
            editable: true,
            editableType: 'text',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Save',
            callback: 'onSaveItem',
            icon: 'save',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Cancel',
            callback: 'onCancel',
            icon: 'close',
          },
        ],
        values: {},
      },
      itemPrevData: null,
    };
  },
  mounted() {
    if (this.$route.name === 'AssessmentDetails') {
      this.title = 'Assessment Details';
      this.subtitle = null;
      this.editable = false;
      this.getAssessment();
    }
  },
  methods: {
    saveAssessment() {
      if (this.$route.name === 'AssessmentDetails') {
        const form = { ...this.form };
        this.request(`${this.$API.ASSESSMENTS}${this.$route.params.id}`, 'put', form, () => {
          this.fireSuccess('Assessment updated succesfully');
          this.editable = false;
          this.$refs.vtable.init();
        });
      } else {
        this.request(this.$API.ASSESSMENTS, 'post', this.form, () => {
          this.fireSuccess('Assessment created succesfully');
          this.$router.push({ name: 'Assessments' });
        });
      }
    },
    classroomSelected(classroom) {
      this.selClassroom = classroom;
      this.$refs.vtable.init();
    },
    getAssessment() {
      this.request(`${this.$API.ASSESSMENTS}${this.$route.params.id}`, 'get', null, ({ data }) => {
        const aux = data;
        aux.date = moment(data.date);
        this.form = aux;
        this.selectorOpen = true;
      });
    },
    getAssessmentStudents(params) {
      this.request(`${this.$API.ASSESSMENTS}${this.$route.params.id}/entries`,
        'get',
        {
          ...params,
          classroom_id: this.selClassroom.classroom_id,
        },
        ({ data }) => {
          this.vTable.values = data;
          this.vTable.values.data = this.vTable.values.data.map((x) => ({
            ...x,
            editable: false,
            onSaveItem: false,
            onCancel: false,
          }));
        });
    },
    onEdit(item) {
      item.editable = true;
      item.onEdit = false;
      item.onSaveItem = true;
      item.onCancel = true;

      this.itemPrevData = { ...item };
    },
    onSaveItem(item) {
      this.request(
        `${this.$API.ASSESSMENTS}${this.$route.params.id}/entries/${item.assessment_enrolment_id}`,
        'put',
        { ...item },
        () => {
          this.fireSuccess('Assessment updated succesfully');
          this.editable = false;
          this.$refs.vtable.init();
        },
        () => {
          item.editable = false;
          item.onEdit = true;
          item.onSaveItem = false;
          item.onCancel = false;
        },
      );
    },
    onCancel(item) {
      item.grade = this.itemPrevData.grade;
      item.participative_grade = this.itemPrevData.participative_grade;
      item.receptive_grade = this.itemPrevData.receptive_grade;
      item.productive_grade = this.itemPrevData.productive_grade;
      item.final_grade = this.itemPrevData.final_grade;
      item.remark = this.itemPrevData.remark;
      item.editable = false;
      item.onEdit = true;
      item.onSaveItem = false;
      item.onCancel = false;

      this.itemPrevData = null;
    },
  },
};
</script>

<style>

</style>
