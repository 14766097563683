<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Select a Classroom
    </template>
    <template slot="body">
      <ClassroomsSelect
        v-if="teacher_id"
        :key="teacher_id"
        class="text-left"
        :parameters="{teacher_id:teacher_id, level_id: courseLevelId}"
        @change="onSelClassroom"
        @ready="onSelectReady"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-primary"
        @click="selectCroom"
      >
        Select
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { ClassroomsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    ClassroomsSelect,
  },
  props: {
    courseLevelId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    selClassroom: null,
    teacher_id: null,
  }),
  mounted() {
    this.teacher_id = this.user.is_teacher ? this.user.user_id : 'all';
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    onSelectReady(classrooms) {
      if (!classrooms.length && this.teacher_id !== 'all') {
        this.teacher_id = 'all';
      }
    },
    onSelClassroom(croom) {
      this.selClassroom = croom;
    },
    selectCroom() {
      if (this.selClassroom) {
        this.$emit('onSelect', this.selClassroom);
        this.close();
      } else {
        this.fireError('You have to select a Classroom');
      }
    },
  },
};
</script>
<style></style>
